export default class CheckImport {
  checkSelector(sel) {
    return document.querySelector(sel)
  }

  loader(tests = [{ selector: '', moduleParams: {}, id: '' }]) {
    const found = tests.filter(test => this.checkSelector(test.selector))
    if (found.length === 0) {
      return Promise.resolve({ type: this.getId(), pass: true })
    }
    try {
      return this.loadModule(found)
    } catch (error) {
      return { type: this.getId(), error }
    }
  }

  getModuleParamDefaults(item) {
    return { selector: item.selector }
  }

  getId() {
    throw new Error('getId function must be extended')
  }

  loadModule() {
    throw new Error('load function must be extended')
  }

  process(found = [{ selector: '', moduleParams: {}, id: '' }]) {
    return module => {
      return found.map(item => {
        // eslint-disable-next-line no-undef
        const initParams = Object.assign({}, this.getModuleParamDefaults(item), item.moduleParams)
        const Init = module.default
        return {
          type: this.getId(),
          module: new Init(initParams),
          initParams,
          id: item.id,
          selector: item.selector,
        }
      })
    }
  }
}
