// Todo: Import necessary polyfills for ie11 in separate file before this entrypoint.
// Vielleicht https://github.com/babel/babel/issues/9872
// oder https://stackoverflow.com/questions/49236325/babel-preset-does-not-provide-support-on-ie11-for-object-assign-object-doesn
// import 'babel-polyfill'

// Todo: Import Loader -> hide everythign and onlyl show loader until App is loaded
import { flatten } from 'lodash-es'

import LoadApp from './js/partLoaders/loadApp'
import loadPolyfills from '@/js/partLoaders/loadpolyfills'
import loadServiceWorker from '@/js/partLoaders/loadServiceWorker'

// import Loader from './js/modules/Loader'

const parts = [
  // {
  //   LoadModule: new LoadServices(),
  //   loaderParams: [
  //     {
  //       selector: '',
  //       moduleParams: {},
  //       id: 'Services',
  //     },
  //   ],
  // },
  {
    LoadModule: new LoadApp(),
    loaderParams: [
      {
        selector: '',
        moduleParams: {},
        id: 'App',
      },
    ],
  },
]
loadServiceWorker()
loadPolyfills().then(async () => {
  document.documentElement.classList.remove('no-js')

  /* eslint-disable no-unused-vars */
  const staticModules = []
  // staticModules.push({
  //   type: 'SVGStore',
  //   module: new SVGStore(__svg__),
  // })

  /* eslint-disable no-unused-vars */
  const modules = await [
    ...staticModules,
    ...flatten(await Promise.all(parts.map(part => part.LoadModule.loader(part.loaderParams)))),
  ]
})
