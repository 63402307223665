import CheckImport from './CheckImport'

export default class LoadApp extends CheckImport {
  getId() {
    return 'App'
  }

  checkSelector() {
    return true
  }

  loadModule(found = [{ selector: '', moduleParams: {}, id: '' }]) {
    return import(/* webpackChunkName: "App" */ '../modules/App').then(this.process(found))
  }
}
